import { useProgress } from '@marcoschulte/vue3-progress';
import { createRouter, createWebHistory } from 'vue-router';
const progresses = [];

// import pages
const HomeAdmin = () => import('@/pages/admin/Home.vue');
const HomeUser = () => import('@/pages/user/Home.vue');


// Manage Pages 
const ManagePages = () => import ('@/pages/admin/manage/ManagePages');  
const EditPages = () => import ('@/pages/admin/manage/EditPages');  
const CreatePages = () => import ('@/pages/admin/manage/CreatePages');  
const NewPage = () => import ('@/pages/admin/manage/NewPage');  

// User List
const UserList = () => import('@/pages/admin/user-manage/UserList');
const UserListActive = () => import('@/pages/admin/user-manage/UserListActive');
const UserListSuspend = () => import('@/pages/admin/user-manage/UserListSuspend');
const UserListInActive = () => import('@/pages/admin/user-manage/UserListInActive');

//Profile pages
const ProfileAdmin = () => import('@/pages/admin/Profile');
const ProfileUser = () => import('@/pages/user/Profile');
const UserProfile = () => import('@/pages/admin/UserProfile');

// Edits Page 
const EditName = () => import('@/pages/edits/EditName')
const EditEmail = () => import('@/pages/edits/EditEmail')
const EditCompany = () => import('@/pages/edits/EditCompany')
const EditAddress = () => import('@/pages/edits/EditAddress')
const EditAbout = () => import('@/pages/edits/EditAbout')
const EditSocial = () => import('@/pages/edits/EditSocial')

// ui components
const Accordion = () => import('@/pages/components/Accordion');
const Alert = () => import('@/pages/components/Alert');
const Badge = () => import('@/pages/components/Badge');
const Breadcrumb = () => import('@/pages/components/Breadcrumb');
const Buttons = () => import('@/pages/components/Buttons');
const ButtonGroup = () => import('@/pages/components/ButtonGroup');
const Cards = () => import('@/pages/components/Cards');
const Carousel = () => import('@/pages/components/Carousel');
const CloseButton = () => import('@/pages/components/CloseButton');
const Collapse = () => import('@/pages/components/Collapse');
const Dropdown = () => import('@/pages/components/Dropdown');
const ListGroup = () => import('@/pages/components/ListGroup');
const Modal = () => import('@/pages/components/Modal');
const Tabs = () => import('@/pages/components/Tabs');
const Offcanvas = () => import('@/pages/components/Offcanvas');
const Pagination = () => import('@/pages/components/Pagination');
const Placeholders = () => import('@/pages/components/Placeholders');
const Popovers = () => import('@/pages/components/Popovers');
const Progress = () => import('@/pages/components/Progress');
const Spinners = () => import('@/pages/components/Spinners');
const Toasts = () => import('@/pages/components/Toasts');
const Tooltips = () => import('@/pages/components/Tooltips');


// auths pages
const AuthRegister = () => import('@/pages/auths/AuthRegister');
const AuthSuccess = () => import('@/pages/auths/AuthSuccess');
const AuthLogin = () => import('@/pages/auths/AuthLogin');
const AuthReset = () => import('@/pages/auths/AuthReset');

// Errors Page
const NotFound = () => import('@/pages/error/NotFound.vue');
const GatewayError = () => import('@/pages/error/GatewayError.vue');


// system settings 
const Settings = () => import('@/pages/settings/Settings');
const SystemStatus = () => import('@/pages/settings/SystemStatus');
const WebsiteSetting = () => import('@/pages/settings/WebsiteSetting');
const GeneralSettings = () => import('@/pages/settings/GeneralSettings');
const ApiSettings = () => import('@/pages/settings/ApiSettings');
const EmailConfig = () => import('@/pages/settings/EmailConfig');
const BrandingTheming = () => import('@/pages/settings/BrandingTheming');
const UserDashboard = () => import('@/pages/settings/UserDashboard');
const MiscellPages = () => import('@/pages/settings/MiscellPages');

const routes = [
  {
    path: '/',
    name: 'Login Panel',
    component: AuthLogin
  },
  {
    path: '/admin/home',
    name: 'Analytics Admin',
    component: HomeAdmin,
    alias: '/home'
  },
  {
    path: '/user/home',
    name: 'Analytics User',
    component: HomeUser,
    alias: '/home'
  },
  {
    path: '/admin/manage/pages',
    name: 'ManagePages',
    component: ManagePages,
  },
  {
    path: '/admin/manage/pages/edit',
    name: 'EditPages',
    component: EditPages,
  },
  {
    path: '/admin/manage/pages/create',
    name: 'CreatePages',
    component: CreatePages,
  },
  {
    path: '/admin/manage/pages/new-page',
    name: 'NewPage',
    component: NewPage,
  },
  {
    path: '/admin/user-manage/user-lists',
    name: 'User list',
    component: UserList,
  },
  {
    path: '/admin/admin-profile',
    name: 'Admin Profile',
    component: ProfileAdmin,
  },
  {
    path: '/user/my-profile/edit-name',
    name: "User Edit Name",
    component: EditName
  },
  {
    path: '/admin/admin-profile/edit-name',
    name: "Edit Name",
    component: EditName
  },
  {
    path: '/user/my-profile/edit-email',
    name: "User Edit Email",
    component: EditEmail
  },
  {
    path: '/admin/admin-profile/edit-email',
    name: "Edit Email",
    component: EditEmail
  },
  {
    path: '/user/my-profile/edit-company',
    name: "User Edit Company",
    component: EditCompany
  },
  {
    path: '/admin/admin-profile/edit-company',
    name: "Edit Company",
    component: EditCompany
  },
  {
    path: '/user/my-profile/edit-address',
    name: "User Edit Address",
    component: EditAddress
  },
  {
    path: '/admin/admin-profile/edit-address',
    name: "Edit Address",
    component: EditAddress
  },
  {
    path: '/user/my-profile/edit-about',
    name: "User Edit About",
    component: EditAbout
  },
  {
    path: '/admin/admin-profile/edit-about',
    name: "Edit About",
    component: EditAbout
  },
  {
    path: '/admin/settings',
    name: "System Settings",
    component: EditAbout
  },
  {
    path: '/user/my-profile/edit-social',
    name: "User Edit Social",
    component: EditSocial
  },
  {
    path: '/admin/admin-profile/edit-social',
    name: "Edit Social",
    component: EditSocial
  },
  {
    path: '/admin/user-manage/user-lists',
    name: 'User list',
    component: UserList,
  },
  {
    path: '/admin/user-manage/user-lists/active',
    name: 'User list Active',
    component: UserListActive,
  },
  {
    path: '/admin/user-manage/user-lists/in-active',
    name: 'User list InActive',
    component: UserListInActive,
  },
  {
    path: '/admin/user-manage/user-lists/suspend',
    name: 'User list Suspend',
    component: UserListSuspend,
  },
  {
    path: '/admin/user-manage/user-lists/user-profile/:id',
    name: 'User Profile',
    component: UserProfile,
  },
  {
    path: '/admin/settings',
    name: 'System Settings',
    component: Settings,
  },
  {
    path: '/admin/settings/web-setting',
    name: 'Website Settings',
    component: WebsiteSetting,
  },
  {
    path: '/admin/settings/general',
    name: 'General Settings',
    component: GeneralSettings,
  },
  {
    path: '/admin/settings/api-setting',
    name: 'Third-Party APIs',
    component: ApiSettings,
  },
  {
    path: '/admin/settings/system-status',
    name: 'System Status',
    component: SystemStatus,
  },
  {
    path: '/admin/settings/user-dashboard',
    name: 'User Dashboard',
    component: UserDashboard,
  },
  {
    path: '/admin/settings/branding-and-theming',
    name: 'Branding & Theming',
    component: BrandingTheming,
  },
  {
    path: '/admin/settings/email-config',
    name: 'Email Configuration',
    component: EmailConfig,
  },
  {
    path: '/admin/settings/miscell-setting',
    name: 'Miscellaneous',
    component: MiscellPages,
  },
  {
    path: '/user/my-profile',
    name: 'My Profile',
    component: ProfileUser,
  },
  {
    path: '/admin/components/accordion',
    name: 'Accordion',
    component: Accordion
  },
  {
    path: '/admin/components/alerts',
    name: 'Alert',
    component: Alert
  },
  {
    path: '/admin/components/badge',
    name: 'Badge',
    component: Badge
  },
  {
    path: '/admin/components/breadcrumb',
    name: 'Breadcrumb',
    component: Breadcrumb
  },
  {
    path: '/admin/components/buttons',
    name: 'Buttons',
    component: Buttons
  },
  {
    path: '/admin/components/button-group',
    name: 'Button group',
    component: ButtonGroup
  },
  {
    path: '/admin/components/cards',
    name: 'Cards',
    component: Cards
  },
  {
    path: '/admin/components/carousel',
    name: 'Carousel',
    component: Carousel
  },
  {
    path: '/admin/components/close-button',
    name: 'Close button',
    component: CloseButton
  },
  {
    path: '/admin/components/collapse',
    name: 'Collapse',
    component: Collapse
  },
  {
    path: '/admin/components/dropdown',
    name: 'Dropdowns',
    component: Dropdown
  },
  {
    path: '/admin/components/list-group',
    name: 'List group',
    component: ListGroup
  },
  {
    path: '/admin/components/modal',
    name: 'Modal',
    component: Modal
  },
  {
    path: '/admin/components/tabs',
    name: 'Tabs',
    component: Tabs
  },
  {
    path: '/admin/components/offcanvas',
    name: 'Offcanvas',
    component: Offcanvas
  },
  {
    path: '/admin/components/pagination',
    name: 'Pagination',
    component: Pagination
  },
  {
    path: '/admin/components/placeholders',
    name: 'Placeholders',
    component: Placeholders
  },
  {
    path: '/admin/components/popovers',
    name: 'Popovers',
    component: Popovers
  },
  {
    path: '/admin/components/progress',
    name: 'Progress',
    component: Progress
  },
  {
    path: '/admin/components/spinners',
    name: 'Spinners',
    component: Spinners
  },
  {
    path: '/admin/components/toasts',
    name: 'Toasts',
    component: Toasts
  },
  {
    path: '/admin/components/tooltips',
    name: 'Tooltips',
    component: Tooltips
  },

  {
    path: '/auths/auth-register',
    name: 'Auth Register',
    component: AuthRegister
  },
  {
    path: '/auths/auth-success',
    name: 'Auth Success',
    component: AuthSuccess
  },
  {
    path: '/auths/auth-login',
    name: 'Auth Login',
    component: AuthLogin
  },
  {
    path: '/auths/auth-reset',
    name: 'Forgot Password',
    component: AuthReset
  },
  {
    path: '/error/gateway-error',
    name: 'Gateway Error',
    component: GatewayError
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page NotFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
});


// this function dynamically change the route title of app and Redirect user to login page if not logged in
router.beforeEach((to, from, next) => {

  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`

  const isAuthenticated = localStorage.getItem('appBoardAuthenticated')
  
  console.log(isAuthenticated);

  const publicRoutes = ['/auths/auth-register', '/auths/auth-login', '/auths/auth-reset'];

  if (publicRoutes.includes(to.path) || isAuthenticated) {

    const isExcludedRoute = to.path === '/' || to.path.startsWith('/auth');

    if (!isExcludedRoute) {
      const progress = useProgress().start();
      progresses.push(progress);
    }

    next();

  } else {
    next('/auths/auth-login');
  }
})

router.afterEach(() => {
  progresses.pop()?.finish();
})

export default router
